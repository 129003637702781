import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Per_Page_Dropdown, Select2Data, getDimension } from "./common";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://192.168.14.128:8520";
  // const IMG_URL = "http://127.0.0.1:8520";
  // const IMG_URL = "http://192.168.16.56:8520";
  // const IMG_URL = "https://profcymacareernodenew.profcymabackend.com:8522";
  // const IMG_URL = "http://profcymacareernode.profcymabackend.com:8520";
  // const IMG_URL = "http://profcymacareernode.profcymabackend.com:8520"

  // Live On Secure
  const IMG_URL = "https://profcyma.com.hostelbliss.com:8523";

  const navigate = useNavigate();
  const [signin, setSignin] = useState(false);
  const [profileStatus, setprofileStatus] = useState("");
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [token, setToken] = useState("");

  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getuserData = async (id) => {
    const response = await getData(
      `/job-portal/customer-management/users-sing-in/${id}`,
      { id: id }
    );
    await setUserData(response?.data);
  };

  const isTokenValid = async () => {
    const token = Cookies.get("llp_web_security");
    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("llp_web_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        // navigate("/");
      } else {
        // getuserData(decodedToken.user);
        getuserData(decodedToken.user);
        setUsertype(decodedToken.type);
        setSignin(true);
      }
    } else {
      setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        // token,
        // setToken,
        profileStatus,
        setprofileStatus,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        getDimension,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
